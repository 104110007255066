const CryptoJS = require('crypto-js') // 引用AES源码js
import JSEncrypt from 'jsencrypt' // RSA2048
const key = CryptoJS.enc.Utf8.parse('edFabJn3ZA==7JVk') // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412') // 十六位十六进制数作为密钥偏移量

// 解密方法
function Decrypt(word) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

// 加密方法
function Encrypt(word) {
  const srcs = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.ZeroPadding })
  return encrypted.ciphertext.toString().toUpperCase()
}

// RSA加密
function RSAEncrypt(password, publicKey) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  const passwordEncryp = encryptor.encrypt(password) // 对密码进行加密
  return passwordEncryp
}
// RSA解密
function RSADecrypt(password, privateKey) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  const passwordEncryp = encryptor.decrypt(password) // 对密码进行解密
  return passwordEncryp
}
export {
  Decrypt,
  Encrypt,
  RSAEncrypt,
  RSADecrypt
}
