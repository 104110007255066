var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("Number", {
            attrs: { prefix: "+86" },
            on: { blur: _vm.searchErrorNUm },
            model: {
              value: _vm.globForm.phoneNum,
              callback: function ($$v) {
                _vm.$set(
                  _vm.globForm,
                  "phoneNum",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "globForm.phoneNum",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c(
            "a-input-password",
            {
              model: {
                value: _vm.globForm.password,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.globForm,
                    "password",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "globForm.password",
              },
            },
            [
              _c("a-icon", {
                attrs: { slot: "prefix", type: "lock" },
                slot: "prefix",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("GetCheckCode", {
            attrs: {
              "can-use":
                String(_vm.globForm.phoneNum).length !== 0 && _vm.phoneNum > 0,
            },
            on: { getCode: _vm.getCode },
            model: {
              value: _vm.globForm.code,
              callback: function ($$v) {
                _vm.$set(
                  _vm.globForm,
                  "code",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "globForm.code",
            },
          }),
        ],
        1
      ),
      _vm.isGetPhoneNum
        ? [
            _vm.phoneNum <= 3 && _vm.phoneNum !== 0
              ? _c("div", { staticClass: "surplus" }, [
                  _vm._v("登录剩余可尝试次数为" + _vm._s(_vm.phoneNum) + "次"),
                ])
              : _vm._e(),
            _vm.phoneNum === 0
              ? _c("div", { staticClass: "surplus" }, [
                  _vm._v(
                    "此账号因密码错误次数超限已被锁定！请联系超管或客服解锁"
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
      _c(
        "a-button",
        {
          staticClass: "submit",
          attrs: { type: "primary" },
          on: { click: _vm.onSubmit, enter: _vm.onSubmit },
        },
        [_vm._v("登录")]
      ),
      _c("div", { staticClass: "options" }, [
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            {
              staticClass: "forget cursor",
              on: {
                click: function ($event) {
                  return _vm.changeLoginType(3)
                },
              },
            },
            [_vm._v("忘记密码")]
          ),
        ]),
      ]),
      _vm.visible
        ? _c("div", { staticClass: "dialog" }, [
            _c(
              "div",
              { staticClass: "dialogContent" },
              [
                _c("div", { staticClass: "title" }, [
                  _c("p", [_vm._v("拖动下方滑块完成拼图")]),
                  _c(
                    "div",
                    { staticClass: "icon" },
                    [
                      _c("a-icon", {
                        attrs: { type: "redo" },
                        on: { click: _vm.onRefresh },
                      }),
                      _c("a-icon", {
                        attrs: { type: "close" },
                        on: {
                          click: function ($event) {
                            _vm.visible = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("slideverify", {
                  ref: "dialogopen",
                  attrs: {
                    l: _vm.puzzePass.smallHeight,
                    w: _vm.puzzePass.bigWidth,
                    h: _vm.puzzePass.bigHeight,
                    blocky: _vm.puzzePass.posY,
                    imgurl: _vm.puzzePass.bigImageBase64,
                    miniimgurl: _vm.puzzePass.smallImageBase64,
                    "slider-text": _vm.puzzePass.text,
                  },
                  on: {
                    success: _vm.onSuccess,
                    fail: _vm.onFail,
                    refresh: _vm.onRefresh,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }