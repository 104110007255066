import { render, staticRenderFns } from "./content-header.vue?vue&type=template&id=01b6bc80&scoped=true&"
import script from "./content-header.vue?vue&type=script&lang=js&"
export * from "./content-header.vue?vue&type=script&lang=js&"
import style0 from "./content-header.vue?vue&type=style&index=0&id=01b6bc80&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01b6bc80",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/HRSAAS-WEB-DBSJ-DEV/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01b6bc80')) {
      api.createRecord('01b6bc80', component.options)
    } else {
      api.reload('01b6bc80', component.options)
    }
    module.hot.accept("./content-header.vue?vue&type=template&id=01b6bc80&scoped=true&", function () {
      api.rerender('01b6bc80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/login/components/content-header.vue"
export default component.exports