import { render, staticRenderFns } from "./register.vue?vue&type=template&id=14987d02&scoped=true&"
import script from "./register.vue?vue&type=script&lang=js&"
export * from "./register.vue?vue&type=script&lang=js&"
import style0 from "./register.vue?vue&type=style&index=0&id=14987d02&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14987d02",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/HRSAAS-WEB-DBSJ-DEV/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14987d02')) {
      api.createRecord('14987d02', component.options)
    } else {
      api.reload('14987d02', component.options)
    }
    module.hot.accept("./register.vue?vue&type=template&id=14987d02&scoped=true&", function () {
      api.rerender('14987d02', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/login/components/register.vue"
export default component.exports