var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "common-layout" }, [
    _c("div", { staticClass: "lay-out" }, [
      _c("div", { staticClass: "leftBox" }, [
        _c("div", { staticClass: "logoBox" }, [
          _c("div", { staticClass: "logoImgBox" }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: _vm.logo, alt: _vm.systemName },
            }),
          ]),
          _vm._v(" " + _vm._s(_vm.systemName) + " "),
        ]),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "login-box" }, [
        _c(
          "div",
          { staticClass: "box" },
          [
            _c("contentHeader"),
            _c(
              "div",
              { staticClass: "content" },
              [_c(_vm.componentsName, { tag: "component" })],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "description" }, [
      _c("div", { staticClass: "bottomBox" }),
      _c("div", { staticClass: "title" }, [_vm._v("重塑HR价值")]),
      _c("div", { staticClass: "subTitle" }, [
        _vm._v("业务驱动 · 以人为本 · 业人融合 · 全面赋能"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lay-foot" }, [
      _vm._v(" © 2022 北京数昶信息技术有限公司 All rights reserved. "),
      _c(
        "a",
        {
          attrs: {
            href: "https://beian.miit.gov.cn/#/Integrated/index",
            target: "_blank",
          },
        },
        [_vm._v("京ICP备2022030173号-1")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }