var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forget-password login-form" },
    [
      _c("k-form-build", { ref: "KFB", attrs: { value: _vm.jsonData } }),
      _c(
        "div",
        { staticClass: "btnBox" },
        [
          _c(
            "a-button",
            {
              staticClass: "submit",
              attrs: { type: "primary" },
              on: { click: _vm.nextStep, enterPress: _vm.nextStep },
            },
            [_vm._v("确认")]
          ),
          _c(
            "a-button",
            {
              staticClass: "submit",
              attrs: { type: "link" },
              on: { click: _vm.toHome, enterPress: _vm.toHome },
            },
            [_vm._v("直接体验")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }