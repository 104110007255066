var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-header" }, [
    _c(
      "div",
      { staticClass: "other" },
      [
        _vm.canGoBack
          ? _c("a-icon", {
              style: { fontSize: "20px", "margin-right": "10px" },
              attrs: { type: "left" },
              on: { click: _vm.goBack },
            })
          : _vm._e(),
        _c("div", {
          class: { modelName: _vm.getLoginType === 7 },
          domProps: { innerHTML: _vm._s(_vm.title) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }