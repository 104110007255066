var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forget-password login-form" }, [
    _c(
      "div",
      { staticClass: "form-item" },
      [
        _c(
          "a-input",
          {
            attrs: { type: "text" },
            model: {
              value: _vm.globForm.phoneNum,
              callback: function ($$v) {
                _vm.$set(
                  _vm.globForm,
                  "phoneNum",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "globForm.phoneNum",
            },
          },
          [
            _c("a-icon", {
              attrs: { slot: "prefix", type: "user" },
              slot: "prefix",
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-item" },
      [
        _c("GetCheckCode", {
          attrs: { "can-use": String(_vm.globForm.phoneNum).length !== 0 },
          on: { getCode: _vm.getCode },
          model: {
            value: _vm.globForm.code,
            callback: function ($$v) {
              _vm.$set(_vm.globForm, "code", $$v)
            },
            expression: "globForm.code",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "a-button",
          {
            staticClass: "submit",
            attrs: { type: "primary", disabled: _vm.canUse },
            on: { click: _vm.nextStep, enterPress: _vm.nextStep },
          },
          [_vm._v("下一步")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }