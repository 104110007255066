var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "dialogopen",
      staticClass: "slide-verify",
      style: _vm.widthlable,
      attrs: { id: "slideVerify", onselectstart: "return false;" },
    },
    [
      _c("canvas", { ref: "canvas", attrs: { width: _vm.w, height: _vm.h } }),
      _c("canvas", {
        ref: "block",
        staticClass: "slide-verify-block",
        attrs: { width: _vm.w, height: _vm.h },
      }),
      _c(
        "div",
        {
          staticClass: "slide-verify-info",
          class: { fail: _vm.fail, show: _vm.showInfo },
          on: { click: _vm.refresh },
        },
        [_vm._v(_vm._s(_vm.infoText))]
      ),
      _c(
        "div",
        {
          staticClass: "slide-verify-slider",
          class: {
            "container-active": _vm.containerActive,
            "container-success": _vm.containerSuccess,
            "container-fail": _vm.containerFail,
          },
          style: _vm.widthlable,
        },
        [
          _c(
            "div",
            {
              staticClass: "slide-verify-slider-mask",
              style: { width: _vm.sliderMaskWidth },
            },
            [
              _c(
                "div",
                {
                  staticClass: "slide-verify-slider-mask-item",
                  style: { left: _vm.sliderLeft },
                  on: {
                    mousedown: _vm.sliderDown,
                    touchstart: _vm.touchStartEvent,
                    touchmove: _vm.touchMoveEvent,
                    touchend: _vm.touchEndEvent,
                  },
                },
                [_vm._v(" | | | ")]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }