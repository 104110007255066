var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-password login-form" },
    [
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("a-input-password", {
            attrs: { type: "text", prefix: "新密码" },
            model: {
              value: _vm.globForm.password,
              callback: function ($$v) {
                _vm.$set(
                  _vm.globForm,
                  "password",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "globForm.password",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-item" },
        [
          _c("a-input-password", {
            attrs: { type: "text", prefix: "确认密码" },
            model: {
              value: _vm.globForm.repassword,
              callback: function ($$v) {
                _vm.$set(
                  _vm.globForm,
                  "repassword",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "globForm.repassword",
            },
          }),
        ],
        1
      ),
      _c(
        "a-button",
        {
          staticClass: "submit",
          attrs: { type: "primary", disabled: _vm.canUse },
          on: { click: _vm.onSubmit, enterPress: _vm.onSubmit },
        },
        [_vm._v("确定")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }