var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forget-password login-form" }, [
    _c(
      "div",
      { staticClass: "form-item" },
      [
        _c("Number", {
          attrs: { type: "text", prefix: "+86" },
          model: {
            value: _vm.globForm.phoneNum,
            callback: function ($$v) {
              _vm.$set(
                _vm.globForm,
                "phoneNum",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "globForm.phoneNum",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "form-item" },
      [
        _c("GetCheckCode", {
          attrs: { "can-use": String(_vm.globForm.phoneNum).length !== 0 },
          on: { getCode: _vm.getCode },
          model: {
            value: _vm.globForm.code,
            callback: function ($$v) {
              _vm.$set(
                _vm.globForm,
                "code",
                typeof $$v === "string" ? $$v.trim() : $$v
              )
            },
            expression: "globForm.code",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "a-button",
          {
            staticClass: "submit",
            attrs: { type: "primary", disabled: _vm.canUse },
            on: { click: _vm.nextStep, enterPress: _vm.nextStep },
          },
          [_vm._v("下一步")]
        ),
      ],
      1
    ),
    _vm.visible
      ? _c("div", { staticClass: "dialog" }, [
          _c(
            "div",
            { staticClass: "dialogContent" },
            [
              _c("div", { staticClass: "title" }, [
                _c("p", [_vm._v("拖动下方滑块完成拼图")]),
                _c(
                  "div",
                  { staticClass: "icon" },
                  [
                    _c("a-icon", {
                      attrs: { type: "redo" },
                      on: { click: _vm.onRefresh },
                    }),
                    _c("a-icon", {
                      attrs: { type: "close" },
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("slideverify", {
                ref: "dialogopen",
                attrs: {
                  l: _vm.puzzePass.smallHeight,
                  w: _vm.puzzePass.bigWidth,
                  h: _vm.puzzePass.bigHeight,
                  blocky: _vm.puzzePass.posY,
                  imgurl: _vm.puzzePass.bigImageBase64,
                  miniimgurl: _vm.puzzePass.smallImageBase64,
                  "slider-text": _vm.puzzePass.text,
                },
                on: {
                  success: _vm.onSuccess,
                  fail: _vm.onFail,
                  refresh: _vm.onRefresh,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }